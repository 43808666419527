import { NgModule } from '@angular/core';

import { AdjustBasedOnQuantityPipe } from './adjust-based-on-quantity.pipe';
import { CommaSeparatedPipe } from './comma-separated.pipe';
import { DeliveredByPipe } from './delivered-by.pipe';
import { FormatDateTimePipe } from './format-date-time.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { OrdinalFormatPipe } from './format-ordinal.pipe';
import { LocalCurrencyPipe } from './local-currency.pipe';
import { PadWithZerosPipe } from './pad-with-zeros.pipe';
import { PaymentMethodNamePipe } from './payment-method-name.pipe';
import { SumWithPrecisionPipe } from './sum-with-precision.pipe';

@NgModule({
  declarations: [
    LocalCurrencyPipe,
    FormatNumberPipe,
    PadWithZerosPipe,
    AdjustBasedOnQuantityPipe,
    CommaSeparatedPipe,
    FormatDateTimePipe,
    DeliveredByPipe,
    SumWithPrecisionPipe,
    OrdinalFormatPipe,
    PaymentMethodNamePipe,
  ],
  imports: [],
  exports: [
    LocalCurrencyPipe,
    FormatNumberPipe,
    PadWithZerosPipe,
    AdjustBasedOnQuantityPipe,
    CommaSeparatedPipe,
    FormatDateTimePipe,
    DeliveredByPipe,
    SumWithPrecisionPipe,
    OrdinalFormatPipe,
    PaymentMethodNamePipe,
  ],
})
export class PipesModule {}
